import { useCallback, useEffect, useState } from 'react';

/**
 * Listens to changes in window size. Returns window height and width.
 */

const useWindowSize = () => {
  const isClient = typeof window === 'object';

  const getSize = useCallback(
    () => ({
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }),
    []
  );

  const [windowDimensions, setWindowDimensions] = useState(getSize());

  useEffect(() => {
    if (!isClient) {
      return;
    }

    function handleResize() {
      setWindowDimensions(getSize());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [getSize, isClient]);

  return windowDimensions;
};

export { useWindowSize };
