import { graphql, useStaticQuery } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { Layout, PrimaryIcon, SEO, TeamCard } from '../components';
import { useWindowSize } from '../hooks/useWindowSize';
import { rem, theme } from '../theme';

const TeamBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  & h1 {
    font-weight: ${theme.fontWeights[700]};
    margin: 48px auto 0 auto;
    letter-spacing: 3px;
  }

  & p {
    margin: 32px auto;
    color: ${theme.colors.font.secondary};
    & span {
      font-weight: ${theme.fontWeights[700]};
      color: ${theme.colors.font.primary};
    }
  }

  /* Tablet */
  @media only screen and (min-width: ${theme.breakpoints[3]}px) {
    & p {
      font-size: ${rem(4)};
    }
  }

  /* Desktop */
  @media only screen and (min-width: ${theme.breakpoints[4]}px) {
    & h1 {
      margin: 0 auto;
    }
    & p {
      font-size: ${rem(6)};
    }
  }
`;

const SearchSection = styled.div`
  width: 280px;
  font-size: ${rem(2)};
  @media only screen and (min-width: ${theme.breakpoints[0]}px) {
    width: 380px;
  }
  @media only screen and (min-width: ${theme.breakpoints[1]}px) {
    width: 480px;
  }
  @media only screen and (min-width: ${theme.breakpoints[2]}px) {
    width: 600px;
  }
`;

const TagBox = styled.div`
  display: flex;
  background-color: ${theme.colors.topbar.link_active_button};
  height: auto;
  padding: 32px 148px 8px 148px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & ul {
    padding: 0;
    text-align: center;
    width: 280px;

    & li {
      display: inline;
      padding: 8px;

      & button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        margin-bottom: 18px;

        & span {
          font-size: ${rem(2)} !important;
          color: ${theme.colors.white};
          padding: 8px;
        }

        &.active {
          outline: none;
          & span {
            border-bottom: 3px solid ${theme.colors.font.secondary};
          }
        }

        &:focus {
          outline: 0;
        }
      }
    }
  }

  /* Mobile */
  @media only screen and (min-width: ${theme.breakpoints[0]}px) {
    & ul {
      width: 380px;
      & li {
        & button {
          margin-bottom: 18px;
          & span {
            font-size: ${rem(2)} !important;
          }
        }
      }
    }
  }

  @media only screen and (min-width: ${theme.breakpoints[1]}px) {
    & ul {
      width: 480px;
      & li {
        & button {
          margin-bottom: 18px;
          & span {
            font-size: ${rem(2)} !important;
          }
        }
      }
    }
  }

  /* Tablet and desktop */
  @media only screen and (min-width: ${theme.breakpoints[2]}px) {
    & ul {
      width: auto;
      & li {
        & button {
          margin-bottom: 24px;
          & span {
            font-size: ${rem(3)} !important;
          }
        }
      }
    }
  }
`;

const CountBox = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: ${rem(3)};
  margin-top: 32px;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 16px 0 16px;

  span:nth-child(1) {
    font-weight: ${theme.fontWeights[700]};

    &.team-header {
      display: none;
      @media screen and (min-width: ${theme.breakpoints[4]}px) {
        display: block;
      }
    }

    &.less-visible {
      font-weight: ${theme.fontWeights[400]};
      color: ${theme.colors.font.secondary};
    }
  }
  span:nth-child(2) {
    color: ${theme.colors.font.secondary};
  }
  & ${PrimaryIcon} {
    font-size: ${rem(1)};
    margin-left: 4px;
    color: ${theme.colors.senior};
  }

  @media only screen and (min-width: ${theme.breakpoints[3]}px) {
    font-size: ${rem(6)};

    & ${PrimaryIcon} {
      font-size: ${rem(4)};
    }
  }
`;

const CardHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 3% 0;
`;

const FeaturedPerson = styled.div`
  font-weight: ${theme.fontWeights[700]};
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  color: ${theme.colors.font.primary};
  align-items: center;
  font-size: ${rem(5)};
  & > svg {
    color: ${theme.colors.primary};
    padding-right: 4px;
  }
`;

const RegularPerson = styled.div`
  color: ${theme.colors.font.secondary};
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  font-size: ${rem(5)};
`;
type Tag = {
  name: string;
  value: number;
};

type Person = {
  firstName: string;
  lastName: string;
  image: string;
  team: string;
  education: string[];
  description: string[];
  experience: number;
  hobby: string[];
  language: string[];
  tags: Tag[];
};

type QueryResult = {
  allTeamJson: {
    nodes: Person[];
  };
  teamMetaJson: {
    popularTags: string[];
  };
};

function flatMap<T, U>(
  array: T[],
  callbackfn: (value: T, index: number, array: T[]) => U[]
): U[] {
  return Array.prototype.concat(...array.map(callbackfn));
}

const Tag: FC<{
  name: string;
  selected: string | undefined;
  select: (value: string) => void;
}> = ({ name, selected, select }) => {
  return (
    <li key={name}>
      <button
        className={name === selected ? 'active' : ''}
        onClick={() => select(name)}
      >
        <span>{name}</span>
      </button>
    </li>
  );
};

const Person: FC<{
  firstName: string;
  lastName: string;
  experience: number;
}> = ({ firstName, lastName, experience }) => {
  if (experience)
    return (
      <FeaturedPerson>
        {experience >= 3 && <PrimaryIcon />}
        {firstName} {lastName[0]}.
      </FeaturedPerson>
    );
  return (
    <RegularPerson>
      {firstName} {lastName[0]}.
    </RegularPerson>
  );
};

const TeamPage: React.FC = () => {
  const [selectedTag, setSelectedTag] = useState<string>();
  const [displayOnResolution, setDisplayOnResolution] = useState<boolean>();

  // Make sure that 'window' is used only in browser
  useEffect(() => {
    setDisplayOnResolution(window.innerWidth >= theme.breakpoints[4]);
  }, []);

  const [matchingPeople, setMatchingPeople] = useState<{
    [key: string]: number;
  }>({});

  const displayHandle = () =>
    setDisplayOnResolution(window.innerWidth >= theme.breakpoints[4]);

  useEffect(() => {
    window.addEventListener('resize', displayHandle);

    return () => window.removeEventListener('resize', displayHandle);
  }, [displayOnResolution]);

  const data = useStaticQuery<QueryResult>(graphql`
    {
      allTeamJson(sort: { fields: [firstName, lastName] }) {
        nodes {
          firstName
          lastName
          image
          education
          description
          hobby
          language
          tags {
            name
            value
          }
        }
      }

      teamMetaJson {
        popularTags
      }
    }
  `);

  const flat = flatMap(data.allTeamJson.nodes, it => it.tags);
  const options = [...new Set(flat.map(it => it.name))]
    .map(it => ({
      value: it,
      label: it,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const DerivedMessage = () => {
    if (selectedTag) {
      const matching = flat.filter(it => it.name === selectedTag);
      const total = matching.length;
      const juniors = matching.filter(it => it.value < 3).length;
      let seniorsAmount = 'seniors';
      if (total - juniors === 1) {
        seniorsAmount = 'senior';
      }
      let juniorsAmount = 'juniors';
      if (juniors === 1) {
        juniorsAmount = 'junior';
      }

      return (
        <CountBox>
          <span>{`Found ${total} ${selectedTag} Specialists:`}&nbsp;</span>
          <span>
            <PrimaryIcon />
            <b>
              {`${total - juniors} `}
              {seniorsAmount}{' '}
            </b>
            and{' '}
            <b>
              {juniors} {juniorsAmount}
            </b>
          </span>
        </CountBox>
      );
    }

    return (
      <CountBox>
        <span className="team-header">
          <span className="less-visible">(Y)</span>OUR TEAM
        </span>
      </CountBox>
    );
  };

  useEffect(() => {
    const matching: { [key: string]: number } = {};
    data.allTeamJson.nodes.forEach(person => {
      person.tags.forEach(it => {
        if (it.name === selectedTag) {
          matching[person.firstName + person.lastName] = it.value;
        }
      });
    });

    setMatchingPeople(matching);
  }, [selectedTag]);

  const windowSize = useWindowSize();

  const showTags = (array: string[]) => {
    if (windowSize.width && windowSize.width < theme.breakpoints[3]) {
      let newArray = array.slice(0, 7);
      return newArray;
    } else {
      return array;
    }
  };

  return (
    <Layout>
      <SEO title="Team" />
      <TeamBox>
        <h1>Meet the team</h1>
        <p>
          Our team consists of {data.allTeamJson.nodes.length} people with a
          range <br /> of different skills, including...
        </p>
      </TeamBox>
      <TagBox>
        <SearchSection>
          <Select
            onChange={(it: any) => setSelectedTag((it as any).value)}
            options={options}
            placeholder="Start typing to find a skill..."
          />
        </SearchSection>
        <ul>
          {showTags(data.teamMetaJson.popularTags).map(it => (
            <Tag
              key={it}
              name={it}
              selected={selectedTag}
              select={setSelectedTag}
            />
          ))}
        </ul>
      </TagBox>
      <DerivedMessage />
      <CardHolder>
        {!selectedTag && displayOnResolution
          ? data.allTeamJson.nodes.map(it => (
              <TeamCard
                key={it.firstName + it.lastName}
                hobby={it.hobby}
                language={it.language}
                tags={it.tags}
                firstName={it.firstName}
                lastName={it.lastName}
                image={it.image}
                education={it.education}
                description={it.description}
                tagExperience={matchingPeople[it.firstName + it.lastName]}
              />
            ))
          : data.allTeamJson.nodes
              .filter(it => it.tags.find(tag => tag.name === selectedTag))
              .map(it => (
                <TeamCard
                  key={it.firstName + it.lastName}
                  hobby={it.hobby}
                  language={it.language}
                  tags={it.tags}
                  firstName={it.firstName}
                  lastName={it.lastName}
                  image={it.image}
                  education={it.education}
                  description={it.description}
                  tagExperience={matchingPeople[it.firstName + it.lastName]}
                />
              ))}
      </CardHolder>
    </Layout>
  );
};

export default TeamPage;
